<template>
  <div class="content">
    <!-- dialog nuevo pago tipo -->
    <v-row justify="center">
      <div class="col-md-2" align="left">
        <v-btn fab dark x-small color="primary">
          <a @click="$router.go(-1)">
            <v-icon dark> arrow_back</v-icon>
          </a>
        </v-btn>
      </div>

      <v-dialog v-model="createPagosTipo" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <div class="col-md-10" align="right">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              @click="cargarSucQRDinamicoYCodEntidad"
            >
              Nuevo tipo de pago
            </v-btn>
          </div>
        </template>
        <v-card>
          <v-card-title>
            <span
              class="text-h5 font-weight-bold"
              style="font-family: 'Montserrat' !important;"
              >Nuevo tipo de pago
            </span>
            <v-spacer></v-spacer>
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="close_crear" icon v-bind="attrs" v-on="on">
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-card-title>
          <div v-if="procensando_datos">
            <loader
              object="#4caf50"
              color1="#ffffff"
              color2="#17fd3d"
              size="5"
              speed="2"
              bg="#343a40"
              objectbg="#999793"
              opacity="40"
              disableScrolling="false"
              name="box"
            ></loader>
          </div>

          <!-- NUEVO PAGO TIPO -->
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            style="padding: 0 40px;"
          >
            <!-- NOMBRE PAGO TIPO -->

            <v-text-field
              v-model="Pagotipo"
              :counter="30"
              :rules="namePagotipo"
              label="Pago tipo"
              required
            ></v-text-field>

            <v-text-field
              v-model="BackUrlSuccess"
              :counter="150"
              :rules="nameBackUrlSuccess"
              label="URL Exito"
              required
            >
              <template v-slot:append-outer>
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-checkbox
                        class="pl-2"
                        v-model="defaultSuccess"
                        @change="urlDefault('success')"
                      ></v-checkbox>
                    </div>
                  </template>
                  <span>URL por defecto</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <!--  PENDING URL -->

            <v-text-field
              v-model="BackUrlPending"
              :counter="150"
              :rules="nameBackUrlPending"
              label="URL Pendiente"
              required
            >
              <template v-slot:append-outer>
                <v-tooltip top color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                      <v-checkbox
                        class="pl-2"
                        v-model="defaultPending"
                        @change="urlDefault('pending')"
                      ></v-checkbox>
                    </div>
                  </template>
                  <span>URL por defecto</span>
                </v-tooltip>
              </template>
            </v-text-field>

            <!--  NOTIFICACION URL -->

            <v-text-field
              v-model="BackUrlNotificacionPagos"
              :counter="150"
              :rules="nameBackUrlNotificacionPagos"
              label="URL Notificacion"
              required
            ></v-text-field>

            <!-- Nuevo IncludedChannels -->

            <v-select
              v-model="selectIncludedChannels"
              :items="IncludedChannels.data"
              item-text="Nombre"
              item-value="Id"
              label="Medios de Pago"
              multiple
              :rules="rules.selectMedio"
              required
            ></v-select>

            <!-- Nombre Fantasia -->
            <v-expand-transition>
              <v-row>
                <v-col cols="11">
                  <v-text-field
                    v-if="selectIncludedChannels.includes(5)"
                    v-model="nombreFantasia"
                    label="Nombre Fantasia"
                    :rules="QR"
                    :disabled="tieneCampoSucQR || cuentaConSucQR !== ''"
                  />
                </v-col>
                <v-col cols="1">
                  <v-tooltip
                    color="black"
                    top
                    v-if="selectIncludedChannels.includes(5)"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); cursor: pointer;"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      Figurará este nombre <br />
                      cuando el usuario escanee el QR
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expand-transition>

            <v-expand-transition>
              <v-row v-if="selectIncludedChannels.includes(7)">
                <v-col cols="11">
                  <v-text-field
                    v-model="cod_entidad"
                    label="Código Entidad"
                    :rules="QRFactura"
                    :disabled="
                      tieneCampoCodEntidad || cuentaConCodEntidad !== ''
                    "
                  />
                </v-col>
                <v-col cols="1" class="d-flex align-center justify-center">
                  <v-tooltip color="black" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="grey"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        style="cursor: pointer;"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      Este nombre figurará al escanear un QR en FACTURA
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expand-transition>

            <!-- Nuevo IncludedInstallments -->

            <v-select
              v-model="selectIncludedInstallments"
              :items="IncludedInstallments"
              label="Cuotas"
              multiple
              :rules="rules.selectCuota"
              required
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @mousedown.prevent @click="toggleCuotas">
                  <v-list-item-action>
                    <v-icon
                      :color="
                        selectIncludedInstallments.length > 0 ? 'green' : ''
                      "
                    >
                      {{ iconCuotas }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      style="font-family: 'Montserrat';"
                      class="font-weight-bold"
                    >
                      Seleccionar todos
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:append-item>
                <div class="py-2">
                  Campo Obligatorio (*)
                </div>
              </template>
            </v-select>
            <v-col cols="12" style="padding:0 30px">
              <v-row style="justify-content: space-evenly;">
                <v-checkbox
                  class="ckeck"
                  v-model="cobro_app"
                  label="Habilitar cobro por App"
                ></v-checkbox>
                <!-- <v-checkbox
                    class="ckeck"
                    v-model="cobro_pos"
                    label="Cobro por App"
                ></v-checkbox> -->
              </v-row>
            </v-col>
          </v-form>
          <!-- END NUEVO PAGO TIPO -->

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" text @click="close_crear" class="mx-2">
              Cerrar
            </v-btn>
            <v-btn
              :disabled="!valid"
              :loading="dialog"
              color="green"
              class="white--text"
              @click="guardar_pagotipo()"
            >
              <v-icon small>mdi-content-save</v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- end dialog nuevo pago tipo -->

    <br />
    <!-- DATATABLE -->
    <v-app>
      <v-main>
        <v-container fluid>
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Tipos de pago</h4>
              <p class="card-category"></p>
            </div>
            <!-- SHOW ERROR SECTION -->
            <section v-if="errored">
              <v-alert
                border="left"
                class="mt-3 mx-3 elevation-3"
                color="red lighten-2"
                dark
              >
                Lo sentimos, no es posible obtener la información en este
                momento, por favor intente nuevamente mas tarde.
              </v-alert>
            </section>
            <!-- END SHOW ERROR SECTION -->
            <section v-else>
              <!-- DATOS DE LA TABLA -->
              <!-- FILTRO BUSCAR -->
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="find_in_page"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>
              <!-- END FILTRO BUSCAR -->
              <v-data-table
                :search="search"
                :headers="headers"
                :items="pagostipos_items"
                :loading="cargando"
                :footer-props="footerProps"
                :items-per-page="rowsPerPage"
                loading-text="Cargando... Espere por favor"
                no-results-text="No se encontraron registros"
                no-data-text="No se encontraron registros"
                class="elevation-1 trow"
              >
                <template v-slot:[`item.IncludedChannels`]="{ item }">
                  <v-select
                    :items="item.IncludedChannels"
                    item-text="Nombre"
                    item-value="ChannelsId"
                    label="Medio"
                    no-data-text="Sin datos"
                    @change="(valor) => changeIncludedInstallments(valor, item)"
                  ></v-select>
                </template>

                <template v-slot:[`item.IncludedInstallments`]="{ item }">
                  <v-select
                    :items="item.IncludedInstallments"
                    item-text="Nro"
                    item-value="Nro"
                    label="Cuotas"
                    no-data-text="Sin datos"
                  >
                  </v-select>
                </template>

                <!-- BOTONES DE LA TABLA -->
                <template v-slot:[`item.actions`]="{ item }">
                  <span class="mr-2">
                    <v-tooltip top color="orange">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="orange"
                          dark
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon @click="editItem(item)">create</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar</span>
                    </v-tooltip>
                  </span>
                  <span>
                    <v-tooltip top color="red">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="red"
                          dark
                          fab
                          x-small
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon @click="deleteItem(item)">delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </span>
                </template>

                <!--END  BOTONES DE LA TABLA -->

                <!-- ACCIONES BOTONES -->
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <!-- modal editar Pago Tipo  -->
                    <v-dialog v-model="dialog" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="text-h5">Editar tipo de pago</span>
                          <v-spacer></v-spacer>
                          <v-menu bottom left>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                @click="close"
                                icon
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>close</v-icon>
                              </v-btn>
                            </template>
                          </v-menu>
                        </v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editPagotipo"
                                    :counter="30"
                                    :rules="namePagotipo"
                                    label="Pago tipo"
                                    required
                                  ></v-text-field>
                                </v-col>
                                <!-- BackUrlSuccess -->
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editBackUrlSuccess"
                                    :counter="150"
                                    :rules="nameBackUrlSuccess"
                                    label="BackUrlSuccess"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                                <!-- BackUrlPending -->
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editBackUrlPending"
                                    :counter="150"
                                    :rules="nameBackUrlPending"
                                    label="BackUrlPending"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <!-- BackUrlNotificacionPagos -->
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="editBackUrlNotificacionPagos"
                                    :counter="150"
                                    :rules="nameBackUrlNotificacionPagos"
                                    label="BackUrlNotificacionPagos"
                                    required
                                  ></v-text-field>
                                </v-col>

                                <!-- IncludedChannels -->
                                <v-col cols="12" sm="12">
                                  <v-select
                                    v-model="current_editIncludedChannels"
                                    :items="IncludedChannels.data"
                                    item-text="Nombre"
                                    item-value="Id"
                                    attach
                                    chips
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Medios de pago"
                                    multiple
                                    @change="showQRNombre()"
                                    :rules="[
                                      (v) =>
                                        !!v || 'Medios de pago es requerido',
                                    ]"
                                    required
                                  ></v-select>
                                </v-col>

                                <!-- Nombre Fantasia Edit -->
                                <v-col
                                  cols="12"
                                  v-if="
                                    current_editIncludedChannels.includes(5)
                                  "
                                >
                                  <v-expand-transition>
                                    <v-row>
                                      <v-col cols="11">
                                        <v-text-field
                                          v-model="editNombreFantasia"
                                          label="Nombre Fantasia"
                                          :rules="QR"
                                          :disabled="tieneCampoSucQR"
                                        />
                                      </v-col>
                                      <v-col
                                        cols="1"
                                        class="d-flex align-center justify-center"
                                      >
                                        <v-tooltip color="black" top>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-icon
                                              color="grey"
                                              dark
                                              v-bind="attrs"
                                              v-on="on"
                                              style="cursor: pointer;"
                                            >
                                              mdi-information
                                            </v-icon>
                                          </template>
                                          <span>
                                            Figurará este nombre <br />
                                            cuando el usuario escanee el QR
                                          </span>
                                        </v-tooltip>
                                      </v-col>
                                    </v-row>
                                  </v-expand-transition>
                                </v-col>

                                <v-col
                                  cols="12"
                                  sm="12"
                                  v-if="
                                    current_editIncludedChannels.includes(7)
                                  "
                                >
                                  <v-expand-transition>
                                    <v-row>
                                      <v-col cols="11">
                                        <v-text-field
                                          v-model="current_editCodEntidad"
                                          :counter="150"
                                          :disabled="tieneCampoCodEntidad"
                                          :rules="QRFactura"
                                          label="Código de entidad"
                                          required
                                        />
                                      </v-col>
                                      <v-col
                                        cols="1"
                                        class="d-flex align-center justify-center"
                                      >
                                        <v-tooltip color="black" top>
                                          <template
                                            v-slot:activator="{ on, attrs }"
                                          >
                                            <v-icon
                                              color="grey"
                                              dark
                                              v-bind="attrs"
                                              v-on="on"
                                              style="cursor: pointer;"
                                            >
                                              mdi-information
                                            </v-icon>
                                          </template>
                                          <span>
                                            Este nombre figurará al escanear un
                                            QR en FACTURA
                                          </span>
                                        </v-tooltip>
                                      </v-col>
                                    </v-row>
                                  </v-expand-transition>
                                </v-col>

                                <!-- IncludedInstallments -->
                                <v-col cols="12" sm="12">
                                  <v-select
                                    v-model="current_editIncludedInstallments"
                                    :items="IncludedInstallments"
                                    :menu-props="{ maxHeight: '400' }"
                                    label="Cuotas"
                                    multiple
                                    :rules="[
                                      (v) => !!v || 'Cuotas es requerido',
                                    ]"
                                    required
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" style="padding:0 30px">
                                  <v-row style="justify-content: space-evenly;">
                                    <v-checkbox
                                      class="ckeck"
                                      v-model="edit_cobro_app"
                                      label="Habilitar cobro por App"
                                    ></v-checkbox>
                                    <!-- <v-checkbox
                    class="ckeck"
                    v-model="cobro_pos"
                    label="Cobro por App"
                ></v-checkbox> -->
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancelar
                            </v-btn>
                            <v-btn
                              :disabled="!valid"
                              color="blue darken-1"
                              text
                              @click="actualizar_pagotipo"
                            >
                              Actualizar
                            </v-btn>
                          </v-card-actions>
                        </v-form>
                      </v-card>
                    </v-dialog>
                    <!-- modal eliminar -->
                    <v-dialog v-model="dialogDelete" max-width="75%">
                      <v-card>
                        <v-card-title
                          class="text-h5 justify-center"
                          v-text="PagotipoDelete"
                        ></v-card-title>
                        <div v-if="procensando_datos">
                          <loader
                            object="#4caf50"
                            color1="#ffffff"
                            color2="#17fd3d"
                            size="5"
                            speed="2"
                            bg="#343a40"
                            objectbg="#999793"
                            opacity="40"
                            disableScrolling="false"
                            name="box"
                          ></loader>
                        </div>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDelete"
                          >
                            Cancelar
                          </v-btn>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Aceptar</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:footer>
                  <v-row class="no-gutters mt-2 mx-2">
                    <v-flex xs12>
                      <span>Mostrar</span>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            dark
                            text
                            color="primary"
                            class="ml-2"
                            v-on="on"
                          >
                            {{ rowsPerPage }}
                            <v-icon>arrow_drop_down</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item
                            v-for="(number, index) in rowsPerPageArray"
                            :key="index"
                            @click="updateRowsPerPage(number)"
                          >
                            <v-list-item-title>{{ number }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-flex>
                  </v-row>
                </template>
              </v-data-table>
              <div class="text-center pt-2">
                <v-pagination
                  v-model="pagination.page"
                  :length="pagination.total"
                  :total-visible="pagination.visible"
                  @input="onPageChange"
                ></v-pagination>
              </div>
            </section>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { PagosTipo } from "../../services/pagostipo.js";
import { Clientes } from "../../services/clientes.js";
import { Channels } from "../../services/channels.js";
export default {
  name: "PagosTipo",
  data: () => ({
    eliminar_id: 0,
    current_editIncludedChannels: [],
    current_editCodEntidad: "",
    current_editIncludedInstallments: [],
    pagostipos_items: [],
    cliente_id: 0, // El cliente actual para estos tipos de pagos
    cuenta_id: 0, // La cuenta actual para estos tipos de pagos
    desserts: [],
    cuentas: [],
    cargando: false,
    cobro_app: false,
    dialog: false,
    dialogDelete: false,

    //Para suc_qr_dinamico

    tienePagoTipo: false,

    nombreFantasia: "",
    nombreFantasia2: "",
    editNombreFantasia: "",
    nameFantasia: "",
    tieneCampoSucQR: false,
    cuentaConSucQR: "",

    //Para cod_entidad

    cod_entidad: "",
    cod_entidad2: "",
    //el edit es current_editCodEntidad
    nameCodEntidad: "",
    tieneCampoCodEntidad: false,
    cuentaConCodEntidad: false,

    // mostrar error de api
    errored: false,
    // res indica el resultado de las validaciones
    res: false,
    //loaders para procesar datos de envio
    procensando_datos: false,
    // lista de pagos tipos.
    search: "",
    headers: [
      // { text: "Cuenta", value: "Cuenta" },
      { text: "Nombre", value: "Pagotipo" },
      { text: "Url Exito", value: "BackUrlSuccess", sortable: false },
      { text: "Url Pendiente", value: "BackUrlPending", sortable: false },
      //{ text: "Url Rechazo", value: "BackUrlRejected", sortable: false },
      {
        text: "Url Notificacion",
        value: "BackUrlNotificacionPagos",
        sortable: false,
      },
      {
        text: "Medios de pago",
        value: "IncludedChannels",
        width: "200px",
        sortable: false,
      },
      { text: "Cuotas", value: "IncludedInstallments", width: "100px" },
      { text: "Acciones", value: "actions", sortable: false, width: "110px" },
    ],
    // Estas propiedades customizan el footer por default(valores por defecto del datatable)
    footerProps: {
      "items-per-page-options": [],
      "items-per-page-text": "",
      "prev-icon": "",
      "next-icon": "",
      "page-text": "",
    },
    // paginacion api
    pagination: {
      page: 1,
      total: 0,
      visible: 7,
    },
    // numero de registros por pagina
    rowsPerPageArray: [10, 20, 30],
    rowsPerPage: 10,

    // campos para nuevo pagostipos POST
    createPagosTipo: false,
    CuentasId: "",
    items: [], // array de cuentas del cliente
    Pagotipo: "",
    namePagotipo: [
      (v) => !!v || " Pagotipo es requerido",
      (v) =>
        (v && v.length <= 30) || "Pagotipo no debe superar los 30 caracteres",
    ],
    QR: [
      (v) => !!v || " Nombre Fantasia es requerido por medio pago QR",
      (v) =>
        (v && v.length <= 50) ||
        "Nombre Fantasia no debe superar los 50 caracteres",
    ],
    QRFactura: [
      (v) => !!v || "Código de entidad es requerido por medio pago QR-Factura",
      (v) =>
        (v && v.length <= 50) ||
        "Código de entidad no debe superar los 50 caracteres",
    ],
    selectIncludedChannels: [],
    IncludedChannels: [],
    selectIncludedInstallments: ["1"],
    IncludedInstallments: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
    ],

    BackUrlSuccess: "",
    nameBackUrlSuccess: [
      (v) => !!v || " BackUrlSuccess es requerido",
      (v) =>
        (v && v.length <= 150) ||
        "BackUrlSuccess no debe superar los 150 caracteres",
    ],

    BackUrlPending: "",
    nameBackUrlPending: [
      (v) => !!v || " BackUrlPending es requerido",
      (v) =>
        (v && v.length <= 150) ||
        "BackUrlPending no debe superar los 150 caracteres",
    ],
    BackUrlRejected: "https://www.google.com/",

    BackUrlNotificacionPagos: "",
    nameBackUrlNotificacionPagos: [
      (v) => !!v || " Url Notificacion de Pagos es requerido",
      (v) =>
        (v && v.length <= 150) ||
        "Url Notificacion de Pagos no debe superar los 150 caracteres",
    ],

    // campos para editar pago tipos PUT
    editID: "",
    editCuentasId: "",
    edititems: [],
    editPagotipo: "",
    editselectIncludedChannels: [],
    editselectIncludedInstallments: [],
    editBackUrlSuccess: "",
    editBackUrlPending: "",
    editBackUrlRejected: "https://www.google.com/",
    editBackUrlNotificacionPagos: "",
    edit_cobro_app: false,
    //campos a eliminar
    PagotipoDelete: "",
    // validaciones
    valid: false,
    rules: {
      selectMedio: [(v) => v.length > 0 || "El medio de pago es requerido"],
      selectCuota: [
        (v) =>
          v.length > 0 || "Debe elegir la/s cuotas asociadas al medio crédito",
      ],
    },

    defaultSuccess: false,
    defaultPending: false,
    defaultRejected: false,
    showInputCodEntidad: false,
  }),
  computed: {
    listenCreditChannel() {
      return this.selectIncludedChannels.includes(1);
    },

    iconCuotas() {
      const cuotas = this.IncludedInstallments.length;
      const cuotasSeleccionadas = this.selectIncludedInstallments.length;

      if (cuotas === cuotasSeleccionadas) return "mdi-close-box";
      if (cuotas > 0 && cuotas !== cuotasSeleccionadas) return "mdi-minus-box";

      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.cuenta_id = this.$route.params.cuenta_id;
    this.cliente_id = this.$route.params.cliente_id;
    this.getdatos();
    this.getCuentas();
    this.getChannels();
  },

  methods: {
    cargarSucQRDinamicoYCodEntidad() {
      //Si tiene pago tipo y tiene el campo de SucQRDinamico
      if (this.tieneCampoSucQR) {
        this.nombreFantasia = this.nameFantasia;
      } else if (this.cuentaConSucQR !== "") {
        this.nombreFantasia = this.cuentaConSucQR;
      } else {
        this.nombreFantasia = this.nombreFantasia2;
      }

      if (this.tieneCampoCodEntidad) {
        this.cod_entidad = this.nameCodEntidad;
      } else if (this.cuentaConCodEntidad !== "") {
        this.cod_entidad = this.cuentaConCodEntidad;
      } else {
        this.cod_entidad = this.cod_entidad2;
      }
      //Si la cuenta tiene SucQRDinamico
    },

    initialize() {
      const pagostipos_items = JSON.parse(JSON.stringify(this.desserts));

      pagostipos_items.forEach(function(element) {
        if (element.IncludedChannels) {
          element.IncludedChannels.map(function(channel) {
            if (channel.Channel == "CREDIT") {
              channel.cuotas = element.IncludedInstallments;
            }
          });
        }
        if (!element.IncludedChannels) {
          element.IncludedChannels = [];
          element.IncludedInstallments = [];
        }
      }); // Fin de foreach para cada PagoTipoItem
      // Retorno de la funcion initialize()
      this.pagostipos_items = pagostipos_items;
    },
    changeIncludedInstallments(value, item) {
      // Buscar el pagotipo de desserts en pagostipos_item
      var item_pagotipo = this.pagostipos_items.filter(function(element) {
        return element.Id == item.Id;
      });

      // Obtener el canal de pago
      var canal_filtrado = item_pagotipo[0].IncludedChannels.filter(
        (element) => element.ChannelsId == value
      );
      //Obtener las cuotas del canal de pago canal_filtrado
      var cuotas_filtradas = canal_filtrado[0].cuotas;

      this.desserts.forEach(function(pagotipo) {
        if (pagotipo.Id == item.Id) {
          pagotipo.IncludedInstallments = cuotas_filtradas;
          return pagotipo;
        } else {
          return pagotipo;
        }
      });
    },
    getdatos: function() {
      const params = {
        Number: this.pagination.page,
        Size: this.rowsPerPage,
        IdCuenta: this.cuenta_id,
        CargarTipoPagoChannels: true,
      };
      this.cargando = true;
      PagosTipo.getPagosTipo(params)
        .then((response) => {
          if (response.data.data == null) {
            this.desserts = [];
            this.pagination.page = 1;
            this.pagination.total = 0;
            this.cargando = false;
            this.tieneCampoSucQR = false;
            this.tienePagoTipo = false;
          } else {
            this.tienePagoTipo = true;
            //Para suc_qr_dinamico y cod_entidad
            //Se supone que todos los pagos tipos deberian tener el mismo campo con que uno tenga
            if (Array.isArray(response?.data?.data)) {
              //Para SucQRDinamico
              const tieneCampoEnArreglo = response.data.data.some((item) => {
                if (item.suc_qr_dinamico && item.suc_qr_dinamico !== "") {
                  this.nameFantasia = item.suc_qr_dinamico; // Asigna el valor del primer campo encontrado
                  return true; // Termina el `.some` una vez que encuentra un elemento válido
                }
                return false;
              });

              //Para CodEntidad
              const tieneCampoEnArregloCod_Entidad = response.data.data.some(
                (item) => {
                  if (item.cod_entidad && item.cod_entidad !== "") {
                    this.nameCodEntidad = item.cod_entidad; // Asigna el valor del primer campo encontrado
                    return true; // Termina el `.some` una vez que encuentra un elemento válido
                  }
                  return false;
                }
              );

              //Para SucQRDinamico
              this.tieneCampoSucQR = tieneCampoEnArreglo;

              //Para CodEntidad
              this.tieneCampoCodEntidad = tieneCampoEnArregloCod_Entidad;
            } else {
              //Para SucQRDinamico
              this.tieneCampoSucQR =
                response?.data?.data?.suc_qr_dinamico &&
                response.data.data.suc_qr_dinamico !== "";
              this.nameFantasia = this.tieneCampoSucQR
                ? response?.data?.data?.suc_qr_dinamico
                : "";

              //Para Cod_entidad
              this.tieneCampoCodEntidad =
                response?.data?.data?.cod_entidad &&
                response.data.data.cod_entidad !== "";
              this.nameCodEntidad = this.tieneCampoCodEntidad
                ? response?.data?.data?.cod_entidad
                : "";
            }

            this.desserts = response.data.data;
            this.initialize();
            this.pagination.page = response.data.meta.page.currentPage;
            this.pagination.total = response.data.meta.page.lastPage;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally(() => (this.cargando = false));
    },
    // paginar
    onPageChange() {
      this.getdatos();
    },
    // registros por paginas
    updateRowsPerPage(number) {
      this.rowsPerPage = number;
      this.pagination.page = 1;
      this.getdatos();
    },
    // obtener cuentas. Se usa el servicio de clientes porque trae un cliente con sus cuentas
    getCuentas: function() {
      var data = new FormData();
      data.append("id", this.cliente_id);
      data.append("CargarCuentas", true);

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      Clientes.getClienteID(data, config)
        .then((response) => {
          if (response.data.cuenta == null) {
            this.items = []; // array de cuentas del cliente
          } else {
            //Suc_QR_Dinamico
            this.nombreFantasia2 = response.data.nombre_fantasia;

            //Codigo Entidad
            this.cod_entidad2 = response.data.cod_entidad;

            this.items = response.data.cuenta.filter(
              (cuentaactual) => cuentaactual.id == this.cuenta_id
            ); // de las cuentas del cliente, se toma la cuenta actual del componente

            //Cuetna con suc_qr_dinamico
            this.cuentaConSucQR = this.items[0].suc_qr_dinamico;
            //Cuenta con cod entidad
            this.cuentaConCodEntidad = this.items[0].cod_entidad;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        });
    },

    //  listar channels
    getChannels: function() {
      const params = {
        Number: 1,
        Size: 20,
      };
      Channels.getChannels(params)
        .then((response) => {
          if (response.data.data == null) {
            this.IncludedChannels = [];
          } else {
            this.IncludedChannels = response.data;
          }
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
        })
        .finally();
    },

    //comfirmar nuevo pago tipo
    async guardar_pagotipo() {
      var channels = this.selectIncludedChannels;
      var installments = this.selectIncludedInstallments;
      var payload = {
        CuentasId: this.cuenta_id,
        Pagotipo: this.Pagotipo,
        BackUrlSuccess: this.BackUrlSuccess,
        BackUrlPending: this.BackUrlPending,
        BackUrlRejected: this.BackUrlRejected,
        BackUrlNotificacionPagos: this.BackUrlNotificacionPagos,
        IncludedChannels: channels,
        //Código entidad
        ...(this.selectIncludedChannels.includes(7)
          ? { CodEntidad: this.cod_entidad }
          : { CodEntidad: "" }),

        IncludedInstallments: installments,
        //suc_qr_dinamico
        ...(this.selectIncludedChannels.includes(5)
          ? { SucQrDinamico: this.nombreFantasia }
          : { SucQrDinamico: "" }),
      };

      if (this.cobro_app) payload.CobroApp = this.cobro_app;
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.procensando_datos = true;
        await PagosTipo.nuevo_pagotipo(payload)
          .then(() => {
            this.$toastr.s("El tipo de pago se creó correctamente");
            // actualizar lista de pagos tipos
            this.getdatos();
            // cerrar dialog create channel
            this.createPagosTipo = false;
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.procensando_datos = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //consultar los datos de tipos de pago a modificar
    editItem(item) {
      this.consultar_editar(item);
      this.dialog = true;
    },

    //confirmar actualizar el channel
    async actualizar_pagotipo() {
      var channels = this.current_editIncludedChannels;
      var installments = this.current_editIncludedInstallments;
      var payload = {
        Id: this.editID,
        CuentasId: parseInt(this.cuenta_id),
        Pagotipo: this.editPagotipo,
        BackUrlSuccess: this.editBackUrlSuccess,
        BackUrlPending: this.editBackUrlPending,
        BackUrlRejected: this.editBackUrlRejected,
        BackUrlNotificacionPagos: this.editBackUrlNotificacionPagos,
        IncludedChannels: channels,
        IncludedInstallments: installments,
        CobroApp: this.edit_cobro_app,

        //SucQRDinamico
        ...(this.current_editIncludedChannels.includes(5)
          ? { SucQrDinamico: this.editNombreFantasia }
          : { SucQrDinamico: "" }),

        //Codigo Entidad
        ...(this.current_editIncludedChannels.includes(7)
          ? { CodEntidad: this.current_editCodEntidad }
          : { CodEntidad: "" }),
      };

      // if (this.current_editCodEntidad)
      //   payload.CodEntidad = this.current_editCodEntidad;
      this.validate();
      if (this.res === true) {
        this.error = false;
        this.dialog = true;
        this.procensando_datos = true;
        await PagosTipo.actualizar_pagotipo(payload)
          .then(() => {
            this.$toastr.s("Tipo de pago se actualizo correctamente");
            // actualziar tabla de clientes
            this.getdatos();
            this.close();
          })
          .catch((error) => {
            this.errored = true;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
            this.dialog = false;
          })
          .finally(() => {
            this.showInputCodEntidad = false;
            this.current_editCodEntidad = "";
            this.procensando_datos = false;
          });
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    //consultar el id del tipo de pago a eliminar
    deleteItem(item) {
      this.PagotipoDelete =
        "¿ Desea eliminar tipo de pago " + item.Pagotipo + " ?";
      this.eliminar_id = item.Id;
      this.dialogDelete = true;
    },
    //confirmar delete tipo de pago
    deleteItemConfirm() {
      const params = {
        id: this.eliminar_id,
      };
      this.procensando_datos = true;
      // var self = this;
      PagosTipo.delete_pagotipo(params)
        .then(() => {
          // mensaje de exito
          this.$toastr.s("El tipo de pago se eliminó con éxito");

          this.getdatos();
          this.closeDelete();
        })
        .catch((error) => {
          this.errored = true;
          var data = error.response.data;
          this.error = true;
          this.$toastr.e(data.message);
          this.closeDelete();
        })
        .finally(() => {
          this.procensando_datos = false;
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    // validar formulario actualizar
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },
    close_crear() {
      this.createPagosTipo = false;
      this.Pagotipo = "";
      this.BackUrlSuccess = "";
      this.BackUrlPending = "";
      this.BackUrlNotificacionPagos = "";
      this.selectIncludedChannels = [];
      this.nombreFantasia = "";
      this.cod_entidad = "";
      // this.selectIncludedInstallments = [];
      this.$refs.form.resetValidation();
    },
    //consultar pago tipo por id
    consultar_editar: function(item) {
      const params = {
        Id: item.Id,
        IdCuenta: this.cuenta_id,
        CargarTipoPagoChannels: true,
      };
      this.procensando_datos = true; // se muestra el loader porque tarda un momento en cargar la consulta
      PagosTipo.getPagosTipo(params)
        .then((response) => {
          // Se obtiene un objeto dentro de array
          var PagoTipoEditar = response.data.data[0];

          //cargar los datos en el modal edit pagos tipos
          this.editID = PagoTipoEditar.Id;
          // this.editCuentasId = PagoTipoEditar.CuentasId;
          this.editPagotipo = PagoTipoEditar.Pagotipo;
          this.editBackUrlSuccess = PagoTipoEditar.BackUrlSuccess;
          this.editBackUrlPending = PagoTipoEditar.BackUrlPending;
          this.editBackUrlRejected = PagoTipoEditar.BackUrlRejected;
          this.editBackUrlNotificacionPagos =
            PagoTipoEditar.BackUrlNotificacionPagos;
          this.editselectIncludedChannels = PagoTipoEditar.IncludedChannels;
          this.current_editIncludedChannels = this.editselectIncludedChannels.map(
            (channel) => channel.ChannelsId
          );
          this.editselectIncludedInstallments =
            PagoTipoEditar.IncludedInstallments;
          this.current_editIncludedInstallments = this.editselectIncludedInstallments.map(
            (inst) => inst.Nro
          );

          this.edit_cobro_app = PagoTipoEditar.CobroApp;

          //Para SucQRDinamico
          if (PagoTipoEditar.suc_qr_dinamico === "") {
            this.editNombreFantasia = this.nombreFantasia2;
          } else {
            this.editNombreFantasia = PagoTipoEditar.suc_qr_dinamico;
          }

          //Para CodEntidad
          if (PagoTipoEditar.cod_entidad === "") {
            this.current_editCodEntidad = this.cod_entidad2;
          } else {
            this.current_editCodEntidad = PagoTipoEditar.cod_entidad;
          }
        })
        .catch((error) => {
          var data = error.response.data;
          //mostrar mensaje de error en caso que la variable sea true(tenga datos)
          this.error = true;
          this.msg = data.message;
          this.$toastr.e(data.message);
        })
        .finally(() => {
          this.procensando_datos = false;
        });
    },

    toggleCuotas() {
      const cuotas = this.IncludedInstallments.length;
      const cuotasSeleccionadas = this.selectIncludedInstallments.length;
      this.$nextTick(() => {
        if (cuotasSeleccionadas === cuotas) {
          this.selectIncludedInstallments = [];
        } else {
          this.selectIncludedInstallments = this.IncludedInstallments.slice();
        }
      });
    },

    urlDefault(tipo) {
      const baseUrl = window.location.origin;
      switch (tipo) {
        case "success":
          this.defaultSuccess
            ? (this.BackUrlSuccess = `${baseUrl}/pagoexitoso`)
            : (this.BackUrlSuccess = "");
          break;
        case "pending":
          this.defaultPending
            ? (this.BackUrlPending = `${baseUrl}/pagopendiente`)
            : (this.BackUrlPending = "");
          break;
      }
    },

    showQRNombre() {
      let qr_Factura = this.IncludedChannels.data.find(
        (d) => d.Channel === "QR-FACTURA"
      );
      if (qr_Factura && this.current_editIncludedChannels) {
        this.showInputCodEntidad = this.current_editIncludedChannels.includes(
          qr_Factura.Id
        );
      } else {
        this.showInputCodEntidad = false;
      }
    },
  },
};
</script>
<style scoped>
a {
  color: #f7f7f7;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #50b565;
}
</style>
