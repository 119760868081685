<template>
  <ul class="nav">
    <v-list dark class="mx-2 py-0" style="background: #000 !important;">
      <!-- Pagos, Movimientos, Reversiones -->
      <div
        v-for="item in items"
        :key="item.to"
        v-show="permisos.some((p) => p === item.permiso)"
      >
        <v-tooltip right color="black">
          <template v-slot:activator="{ on }">
            <v-list-item
              link
              :to="item.to"
              :key="item.to"
              active-class="active1"
              style="border-radius: 5px; margin-bottom: 5px;"
              v-on="item.tooltip ? on : ''"
              ><v-list-item-icon class="mb-0 mt-2 mr-2">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title></v-list-item
            ></template
          >
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </div>

      <!-- GENERACION DE PAGO -->
      <v-list-group
        active-class="active1"
        no-action
        dark
        v-for="(link, i) in generar_pago"
        :key="i"
        v-show="permisos.some((p) => p === 'psp.accesos.generar_link_pago')"
      >
        <v-icon slot="prependIcon" class="mr-4">{{ link.action }}</v-icon>
        <template v-slot:activator>
          <v-list-item-content
            style="border-radius: 5px !important; color: #fff !important"
          >
            <v-list-item-title> {{ link.title }} </v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- LINKS DE PAGO  -->
        <v-list-item
          :to="item.to"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
          v-show="
            roles.some((r) => r === 'psp.admin.clientes.wee') ||
              (i === 0 && roles.some((r) => r === 'psp.generar.link.pago'))
          "
          v-for="(item, i) in link.items"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- REPORTES-->
      <v-list-group active-class="active1" no-action dark>
        <v-icon slot="prependIcon" class="mr-4">mdi-chart-bar</v-icon>
        <template v-slot:activator>
          <v-list-item-content
            style="border-radius: 5px !important; color: #fff !important"
          >
            <v-list-item-title> Reportes </v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- CLIENTES (antes seccion Reportes sola) -->
        <v-list-item
          to="/reportes"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
          v-show="
            roles.some(
              (r) =>
                r === 'psp.admin.clientes.wee' ||
                r === 'psp.config.clientes.wee'
            )
          "
        >
          <v-list-item-content>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- REPORTES: Cobranzas, Rendiciones, Reversiones -->
        <v-list-item
          to="/reportes-cobranzas"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
        >
          <v-list-item-content>
            <v-list-item-title>Cobranzas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/reportes-rendiciones"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
        >
          <v-list-item-content>
            <v-list-item-title>Rendiciones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/reportes-reversiones"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
        >
          <v-list-item-content>
            <v-list-item-title>Reversiones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/reportes-retenciones"
          link
          dark
          active-class="active1"
          class="px-5"
          style="border-radius: 5px; margin: 3px 0px 2px"
          v-show="
            roles.some(
              (r) =>
                r === 'psp.config.clientes.wee' ||
                r === 'psp.admin.clientes.wee'
            )
          "
        >
          <v-list-item-content>
            <v-list-item-title>Retenciones</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <!-- Transferencias, Reportes, Accesos -->
      <v-list-item
        link
        v-for="item in items2"
        :to="item.to"
        :key="item.to"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
        v-show="permisos.some((p) => p === item.permiso)"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>

      <!-- ADMINISTRAR -->
      <v-list-group
        v-for="item in administrar"
        :key="item.title"
        v-model="item.active"
        active-class="active1"
        no-action
        dark
        v-show="permisos.some((p) => p === 'psp.accesos.administrar')"
      >
        <v-icon slot="prependIcon" class="mr-4">{{ item.action }}</v-icon>
        <template v-slot:activator>
          <v-list-item-content
            style="border-radius: 5px !important; color: #fff !important;"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <!-- ADMINISTRAR: Clientes, Rubros, Aranceles, Canales de pago, Configuraciones -->
        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          link
          :to="child.to"
          dark
          active-class="active1"
          class="px-4"
          style="border-radius: 5px; margin: 3px 0px 2px;"
          v-show="permisos.some((p) => p === child.permiso)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ child.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <!-- WEBSERVICE -->
      <v-list-item
        link
        to="/webservices"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
        v-show="permisos.some((p) => p === 'psp.admin.webservices')"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3">
          <v-icon>cloud_queue</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Webservice</v-list-item-title>
      </v-list-item>

      <!-- COMISIONES -->
      <v-list-item
        link
        to="/reporte-movimiento"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
        v-show="permisos.some((p) => p === 'psp.accesos.comisiones')"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3">
          <v-icon>timeline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Comisiones</v-list-item-title>
      </v-list-item>

      <!-- SOPORTES -->
      <v-list-item
        link
        to="/soportes"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
        v-show="permisos.some((p) => p === 'psp.admin.clientes')"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3" style="min-width:100%">
          <v-icon>mdi-comment-account-outline</v-icon>
          <span class="ml-1 mt-1">Soporte</span>
          <v-badge
            class="ml-2"
            :value="$store.state.soportes.notificaciones"
            v-if="$store.state.soportes.notificaciones > 0"
            v-show="
              roles.some(
                (r) =>
                  r === 'psp.config.clientes.wee' ||
                  r === 'psp.admin.clientes.wee'
              )
            "
          >
            <span
              class="bg-red-500 text-white px-1 py-0.5 rounded-full text-xs"
            >
              {{ $store.state.soportes.notificaciones }}
            </span>
          </v-badge>
        </v-list-item-icon>
      </v-list-item>

      <v-list-item
        link
        to="/puntos_de_venta"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
        v-show="cuentaPosHabilitada()"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3">
          <v-icon>mdi-map-marker-multiple-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Puntos de venta</v-list-item-title>
      </v-list-item>

      <!-- COMISIONES -->
      <v-list-item
        link
        to="/general"
        active-class="active1"
        style="border-radius: 5px; margin-bottom: 5px;"
      >
        <v-list-item-icon class="mb-0 mt-2 mr-3">
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>

        <v-list-item-title>Pricing</v-list-item-title>
      </v-list-item>
    </v-list>
  </ul>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      permissions: this.$store.state.permission,
      /* ITEMS REPORTES */
      reportes: [
        {
          items: [
            { title: "Clientes", to: "/reportes" },
            { title: "Cobranzas", to: "/reportes-cobranzas" },
            { title: "Rendiciones", to: "/reportes-rendiciones" },
            { title: "Reversiones", to: "/reportes-reversiones" },
            /* { title: "Retenciones", to: "/reportes-retenciones" }, */
          ],
          title: "Reportes",

          action: "mdi-chart-bar",
        },
      ],
      /* ITEMS REPORTES */
      generar_pago: [
        {
          items: [
            //{
            // title: "Link de pago",
            //to: "/generar-link-pago",
            //permiso: "psp.accesos.generar_link_pago",
            //},
            {
              title: "Link de pago",
              to: "/generar-link-pago",
              permiso: "psp.accesos.generar_link_pago",
            },
            {
              title: "QR",
              to: "/generar-qr",
              permiso: "psp.accesos.generar_link_pago",
            },
          ],
          title: "Generar pago",
          action: "mdi-credit-card-outline",
        },
      ],
      /* ITEMS ADMINISTRAR */
      administrar: [
        {
          items: [
            {
              title: "Clientes",
              to: "/administrar-clientes",
              permiso: "psp.admin.clientes",
            },
            {
              title: "Retenciones",
              to: "/administrar-retenciones",
              permiso: "psp.admin.clientes",
            },
            {
              title: "Promociones",
              to: "/administrar-promociones",
              permiso: "psp.admin.clientes",
            },
            {
              title: "Rubros",
              to: "/administrar-rubros",
              permiso: "psp.admin.rubros",
            },
            {
              title: "Aranceles",
              to: "/administrar-aranceles",
              permiso: "psp.admin.aranceles",
            },
            {
              title: "Canales de pago",
              to: "/administrar-canales",
              permiso: "psp.admin.canales",
            },
            {
              title: "Configuraciones",
              to: "/administrar-configuraciones",
              permiso: "psp.admin.configuraciones",
            },
            {
              title: "Archivos",
              to: "/administrar-archivos",
              permiso: "psp.admin.archivos",
            },
            //{
            //title: "Historial",
            //to: "/administrar-historial",
            //permiso: "psp.admin.archivos",
            //},
            {
              title: "Usuarios Bloqueados",
              to: "/administrar-usuarios-bloqueados",
              permiso: "psp.admin.archivos",
            },
          ],
          title: "Administrar",
          action: "dashboard_customize",
        },
      ],
      items: [
        {
          title: "Pagos",
          icon: "card_membership",
          to: "/pagos",
          tooltip: "Pagos que se realizan en el día",
          permiso: "psp.consultar.pagos",
        },
        {
          title: "Movimientos",
          icon: "price_change",
          to: "/movimientos",
          tooltip:
            "Pagos que están disponibles para ser transferidos al cliente",

          permiso: "psp.consultar.movimiento_cuenta",
        },

        /* {
          title: "Reversiones",
          icon: "mdi-cash-refund",
          to: "/contracargos",
          permiso: "psp.accesos.administrar",
        }, */
      ],

      items2: [
        {
          title: "Transferencias",
          icon: "shortcut",
          to: "/transferencias",
          permiso: "psp.consultar.transferencias",
        },
        /*  {
          title: "Reportes",
          icon: "mdi-database-export",
          to: "/reportes",
          permiso: "psp.reportes.pago",
        }, */
        {
          title: "Accesos",
          icon: "group_add",
          to: "/accesos",
          permiso: "rol.index",
        },

        /* {
          title: "Administrar",
          icon: "dashboard_customize",
          superAdmin: true,
          to: "/administrar",
        }, */
      ],
    };
  },

  methods: {
    ...mapActions("soportes", ["getSoportes"]),

    cuentaPosHabilitada() {
      const cuentaActual = this.CLIENTE_CUENTA_DATA.cuenta.find(
        (f) => f.id === this.getCurrentAccountId
      );
      if (!cuentaActual) {
        return false;
      }
      return cuentaActual.cobro_app || cuentaActual.cobro_pos;
    },

    async obtenerNotificaciones() {
      try {
        // Espera a que se resuelva la promesa para obtener las notificaciones
        await this.getSoportes();
      } catch (error) {
        // console.error("Error al obtener las notificaciones:", error);
      }
    },
  },
  mounted() {
    this.obtenerNotificaciones(); // Llama al método para obtener las notificaciones
  },

  computed: {
    ...mapGetters(["isSuperAdmin", "permisos", "roles"]),
    ...mapState("soportes", ["soportes", "notificaciones"]),
    ...mapGetters(["getCurrentAccountId", "CLIENTE_CUENTA_DATA"]),
  },
};
</script>
<style scoped>
/* SCROLL BAR */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #000;
}
/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #000;
}
*::-webkit-scrollbar-track:hover {
  background-color: #000;
}
*::-webkit-scrollbar-track:active {
  background-color: #000;
}
*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #888;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #888;
}
*::-webkit-scrollbar-thumb:active {
  background-color: #888;
}

ul {
  /* scroll */
  overflow-y: scroll;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

.sidebar[data-color="purple"] li.active > a {
  background: linear-gradient(
    to right top,
    #00b1eb 13.02%,
    #1db6c8 48.96%,
    #c2d501 85.42%
  );
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 10pxz -5px rgb(156 39 176 / 40%);
}

.v-tooltip__content {
  left: -15px !important;
  width: 280px;
}

.active1 {
  background: linear-gradient(
    to right top,
    #00b1eb 13.02%,
    #1db6c8 48.96%,
    #c2d501 85.42%
  ) !important;
  box-shadow: 0 4px 20px 0px rgb(0 0 0 / 14%),
    0 7px 10pxz -5px rgb(156 39 176 / 40%) !important;
}

.custom-badge .v-badge-dot {
  background-color: red;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}
</style>
