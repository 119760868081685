import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import NewHome from "../views/NewHome.vue";
import Home1 from "../views/Home1.vue";
import Login from "../views/Login.vue";
import Restore from "../views/RestorePassword.vue";
import Ayuda from "../views/Ayuda.vue";
// import Desarrolladores from "../views/Documentacion/Desarrolladores.vue";
import NewDesarrolladores from "../views/Documentacion/NewDesarrolladores.vue";
import Introduccion from "../views/Documentacion/Introduccion.vue";
import CuentaPagos from "../views/Documentacion/CuentaPagos.vue";
import InstitucionalView from "../views/Documentacion/InstitucionalView.vue";
import Movimientos from "../views/Dasboard/Movimientos.vue";
import General from "../views/Dasboard/General.vue";
import Informes from "../views/Dasboard/InformesBCRA/Index.vue";
import AyudaPagos from "../views/Documentacion/AyudaPagos.vue";
import NewPago from "../views/GenerarPago.vue";
// pruebas para generar pagos
import GeneratePago from "../views/Documentacion/GeneratePago.vue";
import Pagos from "../views/Dasboard/Pagos.vue";
import Perfil from "../views/Dasboard/PerfilUsuario/Perfil.vue";

import Transferencias from "../views/Dasboard/Transferencias/Index.vue";
// import ReportesIndex from "../views/Dasboard/Reportes/ReportesIndex.vue";
import NewReporte from "../views/Dasboard/Reportes/NewReporte.vue";
// import Administrar from "../views/Dasboard/Administrar/Index.vue";
import IndexCuentas from "../views/Dasboard/Cuentas/Index.vue";
import TiposPagosComponent from "../components/Administrar/Pagostipo.vue";
import CuentaComisionComponent from "../components/Administrar/CuentaComision.vue";
import SubCuentas from "../components/Administrar/Clientes/SubCuentas/SubCuentas.vue";
import Accesos from "../views/Dasboard/Accesos/Index.vue";
import RolesPermisos from "../components/Accesos/RolesPermisos.vue";
import UserRol from "../components/Accesos/UserRol.vue";
import store from "../store";
import TerminosView from "@/views/Documentacion/TerminosView.vue";
import TerminosPagador from "@/views/Documentacion/TerminosPagador.vue";
import Refund from "@/views/Dasboard/Contracargos/Refund.vue";
import Preference from "@/components/Preferences/Preference.vue";
import SobreNosotrosView from "@/views/Documentacion/SobreNosotros.vue";
import Solicitud from "@/components/Solicitud-Cuenta/Solicitud.vue";
import PagoExitoso from "../views/Pagos/PagoExitoso.vue";
import PagoPendiente from "../views/Pagos/PagoPendiente.vue";
import PagoCancelado from "../views/Pagos/PagoCancelado.vue";
import Soportes from "../views/Dasboard/Soportes.vue";
import Notificaciones from "../views/Dasboard/Notificaciones.vue";
import PuntoVenta from "../components/Administrar/Clientes/PuntoVenta/PuntodeVenta.vue";
import CostoServicio from "../components/Administrar/Clientes/CostoServicio/CostoServicio.vue";

Vue.use(VueRouter);

function checkPermisos(auth_array, permissions_array) {
  return auth_array.every((element) => {
    return permissions_array.includes(element);
  });
}

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   meta: { requiresAuth: false },
  //   beforeEnter(to, from, next) {
  //     store.commit("setisAutenticated", false);
  //     store.commit("setUsers", "");
  //     localStorage.removeItem("token");
  //     next();
  //   },
  // },
  {
    path: "/",
    name: "Home",
    component: Home1,
    meta: { requiresAuth: false },
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/restore-password/:sistemaid/:codigo",
    name: "Restore",
    component: Restore,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/ayuda",
    name: "Ayuda",
    component: Ayuda,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/sobre-nosotros",
    name: "SobreNosotros",
    component: SobreNosotrosView,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/solicitar-cuenta",
    name: "Solicitud",
    component: Solicitud,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  /* {
    path: "/desarrolladores",
    name: "Desarrolladores",
    component: Desarrolladores,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  }, */
  {
    path: "/desarrolladores",
    name: "NewDesarrolladores",
    component: NewDesarrolladores,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/introduccion",
    name: "Introduccion",
    component: Introduccion,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/cuenta-pagos",
    name: "CuentaPagos",
    component: CuentaPagos,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },

  {
    path: "/institucional",
    name: "InstitucionalView",
    component: InstitucionalView,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/plazos-acreditacion",
    name: "PlazoAcreditacion",
    component: () => import("../views/Documentacion/PlazosAcreditacion.vue"),
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },

  {
    path: "/ayuda-pagos",
    name: "AyudaPagos",
    component: AyudaPagos,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },

  {
    path: "/generate-pagos",
    name: "GeneratePago",
    component: GeneratePago,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },

  {
    path: "/new-pago",
    name: "NewPago",
    component: NewPago,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      let env = store.getters.getEnvironment == "prod";
      if (env) {
        next("/");
      } else {
        next();
      }
    },
  },

  {
    path: "/movimientos",
    name: "Movimientos",
    component: Movimientos,
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },

  // Soportes
  //Temporalmente esta como transferencias la autorizacion
  {
    path: "/soportes",
    name: "Soportes",
    component: Soportes,
    meta: {
      requiresAuth: true,
      authorize: ["psp.admin.clientes"],
    },
  },

  {
    path: "/notificaciones",
    name: "Notificaciones",
    component: Notificaciones,
    meta: {
      requiresAuth: true,
      // //Ver permiso para Avisos
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },

  {
    path: "/administrar-telcocorreo",
    name: "TelCoCorreo",
    component: () => import("@/components/Administrar/TelCoCorreo.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.archivos"] },
  },

  {
    path: "/general",
    name: "General",
    component: General,
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },

  // Puntos de Venta
  {
    path: "/puntos_de_venta",
    name: "PuntosdeVenta",
    component: () => import("@/views/Dasboard/PuntosdeVenta.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },

  {
    path: "/informes-bcra",
    name: "Informes",
    component: Informes,
    meta: { requiresAuth: true, authorize: ["scan.show"] },
  },
  {
    path: "/pagos",
    name: "Pagos",
    component: Pagos,
    meta: { requiresAuth: true, authorize: ["psp.consultar.pagos"] },
  },
  {
    path: "/reportes-cobranzas",
    name: "ReportesCobranzas",
    component: () => import("../views/Dasboard/Reportes/ReporteCobranzas.vue"),
    meta: { requiresAuth: true, authorize: ["psp.consultar.pagos"] },
  },
  {
    path: "/reportes-rendiciones",
    name: "ReportesRendiciones",
    component: () =>
      import("../views/Dasboard/Reportes/ReporteRendiciones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.consultar.pagos"] },
  },
  {
    path: "/reportes-reversiones",
    name: "ReportesReversiones",
    component: () =>
      import("../views/Dasboard/Reportes/ReporteReversiones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.consultar.pagos"] },
  },
  {
    path: "/reportes-retenciones",
    name: "ReportesRetenciones",
    component: () =>
      import("../views/Dasboard/Reportes/ReportesRetenciones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.consultar.pagos"] },
  },
  {
    path: "/transferencias",
    name: "transferencias",
    component: Transferencias,
    meta: { requiresAuth: true, authorize: ["psp.consultar.transferencias"] },
  },
  // uso provisoriamente el permiso de transferencias para esta ruta/vista
  {
    path: "/reportes",
    name: "reportes",
    component: NewReporte,
    meta: { requiresAuth: true, authorize: ["psp.consultar.transferencias"] },
  },

  /* {
    path: "/administrar",
    name: "Administrar",
    component: Administrar,
    // Antes de entrar en la ruta, se checkea que ademas del permiso este usuario tenga el rol de superadmin
    beforeEnter(to, from, next) {
      const rolName = "superadmin";
      const rols = store.state.rol.toString();
      if (!rols.includes(rolName)) {
        next("/");
      } else {
        next();
      }
    },
    meta: {
      requiresAuth: true,
      authorize: [
        "psp.consultar.clientes",
        "psp.consultar.rubros",
        "psp.consultar.canales",
        "psp.consultar.configuraciones",
      ],
    },
  }, */
  /* ADMINISTRAR: Clientes, Rubros, Aranceles, Canales de Pago, Configuraciones, Archivos  */
  {
    path: "/administrar-clientes",
    name: "AdministrarClientes",
    component: () => import("@/components/Administrar/Clientes.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.clientes"] },
  },
  /* retenciones */
  {
    path: "/administrar-retenciones",
    name: "Retenciones",
    component: () =>
      import("@/components/Administrar/Retenciones/Retenciones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.clientes"] },
  },
  {
    path: "/administrar-rubros",
    name: "AdministrarRubros",
    component: () => import("@/components/Administrar/Rubros.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.rubros"] },
  },
  {
    path: "/administrar-aranceles",
    name: "AdministrarAranceles",
    component: () => import("@/components/Administrar/ChannelsArancel.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.admin.aranceles"],
    },
  },
  {
    path: "/administrar-canales",
    name: "AdministrarCanales",
    component: () => import("@/components/Administrar/Channels.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.canales"] },
  },
  {
    path: "/administrar-configuraciones",
    name: "AdministrarConfiguraciones",
    component: () => import("@/components/Administrar/Configuraciones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.configuraciones"] },
  },
  {
    path: "/administrar-archivos",
    name: "AdministrarArchivos",
    component: () => import("@/views/Dasboard/Archivos/IndexArchivos.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.admin.archivos"],
    },
  },
  //Comento historial
  // {
  //   path: "/administrar-historial",
  //   name: "Historial",
  //   component: () => import("@/components/Administrar/Historial/Historial.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     authorize: ["psp.admin.archivos"],
  //   },
  // },
  {
    path: "/administrar-usuarios-bloqueados",
    name: "AdministrarUsuariosBloqueados",
    component: () =>
      import(
        "@/components/Administrar/UsuariosBloqueados/UsuariosBloqueados.vue"
      ),
    meta: { requiresAuth: true, authorize: ["psp.admin.archivos"] },
  },
  {
    path: "/administrar-promociones",
    name: "Promociones",
    component: () => import("@/views/Dasboard/Administrar/Promociones.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.webservices"] },
  },
  {
    path: "/administrar-promociones/:promocion_id/cuentas",
    name: "PromocionesCuentas",
    component: () => import("@/components/Administrar/Promociones/Cuentas.vue"),
    meta: { requiresAuth: true, authorize: ["psp.admin.webservices"] },
  },
  // {
  //   path: "/administrar-historial",
  //   name: "Historial",
  //   component: () => import("@/components/Administrar/Historial/Historial.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     authorize: ["psp.admin.archivos"],
  //   },
  // },
  {
    path: "/webservices",
    name: "WebService",
    component: () => import("../views/Dasboard/WebServices/Index.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.admin.webservices"],
    },
  },

  // {
  //   path: "/generar-link-pago",
  //   name: "CheckoutLink",
  //   component: () => import("../views/Dasboard/GenerarPago.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     authorize: ["psp.accesos.generar_link_pago"],
  //   },
  // },

  {
    path: "/generar-link-pago",
    name: "CheckoutLink2",
    component: () => import("../views/Dasboard/NewGenerarPago.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.accesos.generar_link_pago"],
    },
  },

  {
    path: "/generar-qr",
    name: "CheckoutQR",
    component: () => import("../views/Dasboard/GenerarPagoQR.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.accesos.generar_link_pago"],
    },
  },
  {
    path: "/reporte-movimiento",
    name: "ReporteMovimiento",
    component: () =>
      import("../views/Dasboard/Reportes/ReporteMovimientoIndex.vue"),
    meta: {
      requiresAuth: true,
      authorize: ["psp.accesos.comisiones"],
    },
  },
  /* {
    path: "/soporte",
    name: "SoporteTecnico",
    component: () => import("../components/SoporteTecnico/SoporteTecnico.vue"),
    beforeEnter(to, from, next) {
      const rolName = "superadmin";
      const rols = store.state.rol.toString();
      if (!rols.includes(rolName)) {
        next("/");
      } else {
        next();
      }
    },
    meta: {
      requiresAuth: true,
      authorize: [
        "psp.consultar.clientes",
        "psp.consultar.rubros",
        "psp.consultar.canales",
        "psp.consultar.configuraciones",
      ],
    },
  }, */
  {
    path: "/cliente/:cliente_id/cuentas",
    name: "IndexClienteCuentas",
    component: IndexCuentas,
    meta: { requiresAuth: true, authorize: ["psp.admin.cuentas"] },
  },
  {
    path: "/cliente/:cliente_id/cuentas/:cuenta_id/puntosVentas",
    name: "IndexPuntosVentas",
    component: PuntoVenta,
    meta: { requiresAuth: true, authorize: ["psp.admin.cuentas"] },
  },
  {
    path: "/cliente/:cliente_id/cuentas/:cuenta_id/costo-servicio",
    name: "IndexCostoServicio",
    component: () =>
      import(
        "../components/Administrar/Clientes/CostoServicio/CostoServicio.vue"
      ),
    meta: {
      requiresAuth: true,
      authorize: ["psp.admin.cuentas"],
    },
  },
  {
    path: "/cliente/:cliente_id/retenciones",
    name: "RetencionesClientes",
    component: () =>
      import("../components/Administrar/Retenciones/RetencionesClientes.vue"),
    meta: { requiresAuth: true, authorize: ["psp.consultar.cliente"] },
  },
  {
    path: "/cliente/:cliente_id/cuenta/:cuenta_id/tipos-pagos",
    name: "TiposPagos",
    component: TiposPagosComponent,
    meta: { requiresAuth: true, authorize: ["psp.admin.pagotipo"] },
  },
  {
    path: "/cliente/:cliente_id/cuenta/:cuenta_id/cuenta-comision",
    name: "CuentaComision",
    component: CuentaComisionComponent,
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.cuenta_comision"],
    },
  },
  {
    path: "/cliente/:cliente_id/cuenta/:cuenta_id/sub-cuentas",
    name: "SubCuentas",
    component: SubCuentas,
    meta: { requiresAuth: true, authorize: ["psp.admin.cuentas"] },
  },
  {
    path: "/perfil",
    name: "Perfil",
    component: Perfil,
    meta: { requiresAuth: true, authorize: ["usuario.update"] },
  },
  {
    path: "/terminos",
    name: "TerminosView",
    component: TerminosView,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/terminos-pagador",
    name: "TerminosPagador",
    component: TerminosPagador,
    beforeEnter(to, from, next) {
      store.commit("setisAutenticated", false);
      store.commit("setUsers", "");
      localStorage.removeItem("token");
      next();
    },
  },
  {
    path: "/accesos",
    name: "Accesos",
    component: Accesos,
    meta: {
      requiresAuth: true,
      authorize: ["usuario.index", "rol.index", "permiso.index"],
    },
  },
  {
    path: "/rol-permiso/:id",
    name: "RolesPermisos",
    component: RolesPermisos,
    meta: { requiresAuth: true, authorize: ["permiso.index"] },
  },

  {
    path: "/user/:user_id/usid/:user_sistema_id",
    name: "UserRol",
    component: UserRol,
    meta: { requiresAuth: true, authorize: ["rol.index"] },
  },
  {
    path: "/contracargos",
    name: "Contracargos",
    component: Refund,
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },
  {
    path: "/preference",
    name: "Preference",
    component: Preference,
    meta: {
      requiresAuth: true,
      authorize: ["psp.consultar.movimiento_cuenta"],
    },
  },
  {
    path: "/pagoexitoso",
    name: "PagoExitoso",
    component: PagoExitoso,
  },
  {
    path: "/pagopendiente",
    name: "PagoPendiente",
    component: PagoPendiente,
  },
  {
    path: "/pagocancelado",
    name: "PagoCancelado",
    component: PagoCancelado,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    const authorize = to.meta.authorize;
    const permissions = store.state.permission;

    if (!store.state.isAutenticated) {
      next("/");
      //Cuando intenta acceder a una ruta pregunta si en el array de permissions tiene al menos un permiso de los que se incluyen en el array de authorize de cada ruta
    }

    if (store.state.isAutenticated) {
      // Si esta autenticado y posee los permisos de ruta
      if (checkPermisos(authorize, permissions)) {
        // Puede entrar a la siguiente ruta
        next();
      }
      // Si esta autenticado pero NO posee los permisos de ruta
      if (!checkPermisos(authorize, permissions)) {
        alert("No posee permisos para esta ruta");
      }
    }
  } else {
    next();
  }
});

export default router;
